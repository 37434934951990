import Image from 'next/image';
import React, { useState } from 'react';
import ReadMore from '../../../../components/ReadMoreText';
import SideViewModal from './../../GeneralComponents/SideViewModal'
import { baseUrl } from "/src/config/config";

const EventOrganiserBox = ({ eventData }) => {

    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <p className='mb-2  d-none d-sm-block' style={{ fontWeight: "700", fontSize: "20px", color: '#1C2520' }}>Hosted by</p>
            <div className='organiser-border-container p-3'>
                <div className='d-flex align-items-center'>
                    <div className='organiser-image-cont rounded-circle me-3'>
                        <img src={eventData.organiser_image_url !== null && eventData.organiser_image_url !== "" ? eventData.organiser_image_url : `${baseUrl}/logos/konfhub-octopus.svg`} style={{ width: '100%', height: 'auto' }}
                            alt={`${eventData.name} poster image`} />
                    </div>
                    <div className='d-flex flex-column'>
                        <p className='mb-1 d-block d-sm-none' style={{ fontSize: "18px", color: '#1C2520' }}>Hosted by</p>
                        <span className='organiser-name'>{eventData.organiser_name}</span>
                    </div>

                </div>
                {eventData.organiser_info && <div className='mt-3' style={{ color: '#434343' }}><ReadMore
                    content={eventData.organiser_info}
                    maxChar={"auto"}
                    styles={{
                        fontFamily: "Manrope",
                        fontSize: "15px",
                        color: '#434343'
                    }}
                    color="#000"
                    onClick={() => setOpenModal(prev => !prev)}
                /></div>}
                <div>
                    <p className='mt-3 mb-2' style={{ fontWeight: "500", fontSize: "18px", color: '#434343' }}>Contact Us On</p>
                    <div className='mt-2'>
                        {eventData.organizer_facebook_url && (
                            <a className="me-2" href={eventData.organizer_facebook_url} target={'_blank'}>
                                <img src="/img/facebook-share.svg" alt="" />
                            </a>
                        )}
                        {eventData.organizer_twitter_url && (
                            <a className="me-2" href={eventData.organizer_twitter_url} target={'_blank'}>
                                <img className='twitter-mini' src="/img/twitter-pro-black.svg" alt="" />
                            </a>
                        )}
                        {eventData.organizer_linkedin_url && (
                            <a className="me-2" href={eventData.organizer_linkedin_url} target={'_blank'}>
                                <img src="/img/linkedin-share.svg" alt="" />
                            </a>
                        )}
                        {eventData.organiser_website && (
                            <a className="me-2" href={eventData.organiser_website} target={'_blank'} >
                                <img src="/img/website-black.svg" alt="" />
                            </a>
                        )}
                        {eventData.organiser_email && (
                            <a className="me-2" href={`mailto:${eventData.organiser_email}`} target={'_blank'} >
                                <img src="/img/mail-share.svg" alt="" />
                            </a>
                        )}
                        {eventData.organiser_phone && (
                            <a className="me-2" href={`tel:${eventData.organiser_dial_code + eventData.organiser_phone}`}>
                                <img src="/img/call-share.svg" alt="" />
                            </a>
                        )}
                    </div>
                </div>
                {/* {eventData.organiser_email && <a href={`mailto:${eventData.organiser_email}`} target='_blank' className='minimalistic-btn sec-btn w-100 mt-3 d-block text-center'>Contact</a>} */}
            </div>
            <SideViewModal isOpen={openModal} setIsOpen={setOpenModal} background="#F5F5F5">
                <div className='p-4'>
                    <div className='text-end text-edit'>
                        <button onClick={() => setOpenModal(false)} className='border-0' style={{
                            background: "#F5F5F5",
                            color: "#525252",
                        }}>
                            <i className='fa-solid fa-xmark' style={{ fontSize: "18px" }}></i>
                        </button>
                    </div>
                    <h2>About Organiser</h2>
                    <div className='d-flex align-items-center mb-4'>
                        <div className='organiser-image-cont-2 rounded-circle me-3'>
                            <img src={eventData.organiser_image_url !== null && eventData.organiser_image_url !== "" ? eventData.organiser_image_url : `${baseUrl}/logos/konfhub-octopus.svg`} style={{ width: '100%', height: 'auto' }}
                                alt={`${eventData.name} poster image`} />
                        </div>
                        <span className='organiser-name'>{eventData.organiser_name}</span>
                    </div>
                    <p className="mb-0" style={{ color: '#434343' }} dangerouslySetInnerHTML={{ __html: `${eventData.organiser_info?.replace(/(?:\r\n|\r|\n)/g, '') || ''}` }} />
                    <div className='d-flex mt-4 mb-2 ' style={{ color: '#434343' }}>
                        <p style={{ fontWeight: "500", fontSize: "18px", color: '#434343' }}>Contact Us On: </p>
                        <div className='mx-2'>
                            {eventData.organizer_facebook_url && (
                                <a className="me-2" href={eventData.organizer_facebook_url} target={'_blank'}>
                                    <img src="/img/facebook-share.svg" alt="" />
                                </a>
                            )}
                            {eventData.organizer_twitter_url && (
                                <a className="me-2" href={eventData.organizer_twitter_url} target={'_blank'}>
                                    <img className='twitter-mini' src="/img/twitter-pro-black.svg" alt="" />
                                </a>
                            )}
                            {eventData.organizer_linkedin_url && (
                                <a className="me-2" href={eventData.organizer_linkedin_url} target={'_blank'}>
                                    <img src="/img/linkedin-share.svg" alt="" />
                                </a>
                            )}
                            {eventData.organiser_website && (
                                <a className="me-2" href={eventData.organiser_website} target={'_blank'} >
                                    <img src="/img/website-black.svg" alt="" />
                                </a>
                            )}
                            {eventData.organiser_email && (
                                <a className="me-2" href={`mailto:${eventData.organiser_email}`} target={'_blank'}>
                                    <img src="/img/mail-share.svg" alt="" />
                                </a>
                            )}
                            {eventData.organiser_phone && (
                                <a className="me-2" href={`tel:${eventData.organiser_dial_code + eventData.organiser_phone}`}>
                                    <img src="/img/call-share.svg" alt="" />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </SideViewModal>
            <style jsx>{`
                .organiser-name {
                    font-family: 'Manrope';
                    font-weight: 700;
                    font-size: 1.125rem;
                    color: #1C2520;
                }
                a{
                    cursor:pointer !important ;
                }

                .organiser-border-container {
                    border: 0.8px solid #CACACA;
                    border-radius: 8px;
                }

                .organiser-image-cont {
                    width: 42px;
                    overflow: hidden;
                }

                .organiser-image-cont-2 {
                    width: 58px;
                    overflow: hidden;
                }
                .text-edit{
                    position: relative;
                    top: 30px;
                }
                .twitter-mini{
                    width:29px;
                    height:29px;
                }
                
                @media screen and (max-width: 576px) {
                    .organiser-image-cont {
                        width:60px;
                    }
                    .organiser-name{
                        font-weight: 700;
                        font-size: 22px;
                    }
                }

            `}</style>
        </>
    );
}

export default EventOrganiserBox;